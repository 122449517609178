import { SubscribeForm } from './SubscribeForm'

const Subscribe = () => {
    
    return (
        <div>
            <h1>Subscribe</h1>
            <SubscribeForm />
        </div>
    )
}

export {Subscribe};